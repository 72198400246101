<template>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path fill="var(--icon-color-primary)"
          d="M24 3a21 21 0 1 0 21 21A21 21 0 0 0 24 3zm-1.5 9a1.5 1.5 0 0 1 3 0v1.24a1.5 1.5 0 1 1-3 0zm7.68 22.5H17.82a1.5 1.5 0 0 1 0-3h4.68V19.68h-2.21a1.5 1.5 0 0 1 0-3H24a1.5 1.5 0 0 1 1.5 1.5V31.5h4.68a1.5 1.5 0 0 1 0 3z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconInfoFilled'
}
</script>
