<template>
  <svg viewBox="0 0 14 4" xmlns="http://www.w3.org/2000/svg" class="icon">
    <path d="m285 6.6h14v3h-14z" fill="#918689" transform="matrix(1 -.00127701 .00127701 1 -285.010134 -5.7492)"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCollapse'
}
</script>
<style lang="scss" scoped>
.icon {
  width: 15px;
  height: 15px;
}
</style>
