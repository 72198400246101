<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
    <path d="m807.922-90.925-161.845-160q-30.769 13.616-74.384 22.27-43.616 8.654-91.693 8.654-137.538 0-250.768-76.115Q116.001-372.231 61.54-500q21.539-52.385 59.154-101.115 37.616-48.731 87.077-89.115L85.233-812.384l31.999-32.615L837.691-124.54l-29.769 33.615ZM480-344.232q14.769 0 32.693-3.269 17.923-3.269 28.153-9.039L336.54-560.846q-5.77 11.615-9.039 28.73Q324.232-515 324.232-500q0 65.846 45.576 110.807Q415.385-344.232 480-344.232Zm267.999 36.538L620.922-434.386q6.923-12.153 10.885-29.615 3.961-17.461 3.961-35.999 0-65.23-45.269-110.499Q545.23-655.768 480-655.768q-18.538 0-35.307 3.846-16.769 3.846-29.923 11.769l-105.769-106q33.462-14.461 81.423-24.153 47.961-9.693 93.422-9.693 134.538 0 248.038 76.115Q845.383-627.769 898.46-500q-24.461 57.461-62.192 105.653-37.731 48.192-88.269 86.653Zm-162.23-161.46-136.23-136.231q27.077-9.076 55.961-2.384 28.885 6.692 50.346 27.154 21.846 21.461 31.038 48.615 9.193 27.154-1.115 62.846Z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconEyeOff'
}
</script>
