<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :style="style"
       :width="width || dimensions"
       :height="height || dimensions">
    <use :xlink:href="icon"></use>
  </svg>
</template>

<script>
export default {
  name: 'SvgWrapper',
  props: {
    use: {
      required: true,
      type: String
    },
    dimensions: {
      default: 30,
      type: [String, Number]
    },
    width: {
      type: [String, Number],
      required: false
    },
    height: {
      type: [String, Number],
      required: false
    }
  },
  computed: {
    icon () {
      return '#' + this.use
    },
    style () {
      return `width: ${this.width || this.dimensions}; height: ${this.height || this.dimensions};`
    }
  }
}
</script>
