<template>
  <div itemscope itemtype="https://schema.org/ContactPoint" id="usp-bar" class="bg-chalkboard text-center d-flex">
    <div class="container d-inline-block c-cement-gray font-lato font-size-s align-items-center">
      <div class="row justify-content-center align-items-center h-100">
        <div class="d-none d-md-flex align-items-center ml-2 flex-grow-1">
          <SvgWrapper id="icon-phone" class="f-cement-gray d-inline-block float-left" use="icon_svg_phone"
                      dimensions="20"/>
          <span class="d-inline-block float-left font-libre font-italic ml-1">
            {{ translate('fe_app_pdp_customer_service') }}
          </span>
          <span itemprop="telephone" class="d-inline-block float-left font-weight-bold ml-1 f-white">{{ phoneNumber }}</span>
        </div>
        <span v-if="customerProfile.salutation && ($root.gridBreakpoint > $gridSizes.xs)" class="mx-2 font-weight-bold">
          {{ translate('header_usp_bar_customer_info_customer_salutation', customerProfile.salutation) }}
        </span>
        <span v-if="customerProfile.credits_formatted" id="usp-badge" class="highlight overflow-hidden align-self-start">
          {{ creditsMessage }}
        </span>
        <span v-else-if="customerProfile.meta_navigation" class="link-white" v-html="customerProfile.meta_navigation"></span>
        <a :href="adminUrl" v-if="adminUrl && customerProfile.is_admin" class="d-none d-md-inline mx-2 font-weight-bold link-cement-gray">Admin</a>
      </div>
    </div>
  </div>
</template>

<script>
import SvgWrapper from './SvgWrapper'
import { mapState } from 'pinia'
import { useCustomerStore } from '@/stores/customer'

const _getModules =(() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { PAGE_URL: { ADMIN: adminUrl } },
      { default: { phone: { text: phoneNumber } } }
    ] = await Promise.all([
      import('@/constants/GlobalConstants'),
      import('@/services/SiteConfig')
    ])

    cache = { adminUrl, phoneNumber }
    return cache
  }
})()

export default {
  name: 'UspBar',
  components: { SvgWrapper },
  data () {
    return {
      adminUrl: null,
      phoneNumber: ''
    }
  },
  computed: {
    ...mapState(useCustomerStore, ['customerProfile']),
    creditsMessage () {
      let key
      let data = [this.customerProfile.credits_formatted]
      if (this.customerProfile.credits_expire_days_left === null) {
        key = 'header_usp_bar_customer_info_customer_credits'
      } else if (this.customerProfile.credits_expire_days_left > 1) {
        key = 'header_usp_bar_customer_info_credits_expire_in_more_days'
        data = [...data, this.customerProfile.credits_expire_days_left]
      } else if (this.customerProfile.credits_expire_days_left === 1) {
        key = 'header_usp_bar_customer_info_credits_expire_in_one_day'
        data = [...data, 1]
      } else {
        key = 'header_usp_bar_customer_info_credits_expire_today'
      }
      return this.translate(key, ...data)
    }
  },
  async created () {
    const { adminUrl, phoneNumber } = await _getModules()
    this.phoneNumber = phoneNumber
    this.adminUrl = adminUrl
  }
}
</script>

<style lang="scss" scoped>
#usp-badge {
  color: $white;
  padding: 0 10px;
  background-color: $winered;
  line-height: 25px;
  font-weight: bold;
}

#usp-bar {
  height: 25px;
}

#icon-phone {
  height: 25px;
}
</style>
