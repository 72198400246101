<template>
  <SvgWrapper class="f-clay" use="icon_svg_search" dimensions="22"/>
</template>

<script>
const SvgWrapper = () => import('@/components/basic/atoms/SvgWrapper.vue')

export default {
  name: 'IconSearch',
  components: { SvgWrapper }
}
</script>
