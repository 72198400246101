<template>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path d="m4,4v40h40V4H4Zm32.01,10l-13.34,19.28c-.19.28-.5.46-.84.48-.03,0-.06,0-.08,0-.31,0-.61-.13-.82-.35l-7.66-8.15c-.43-.45-.4-1.17.05-1.59.45-.42,1.17-.4,1.59.05l6.71,7.14,12.55-18.13c.35-.51,1.05-.64,1.57-.28.51.35.64,1.05.29,1.57Z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCheckboxOn'
}
</script>
