<template>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path fill="var(--icon-color-primary)" d="m44,44H4V4h40v40Zm-38-2h36V6H6v36Z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCheckboxOff'
}
</script>
