<template>
  <svg height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg" class="icon">
    <path d="m293.5 7h5.5v3h-5.5v5.5h-3v-5.5h-5.5v-3h5.5v-5.5h3z" fill="#918689" fill-rule="evenodd"
          transform="translate(-285 -1.5)"/>
  </svg>
</template>

<script>
export default {
  name: 'IconExpand'
}
</script>
<style lang="scss" scoped>
.icon {
  width: 15px;
  height: 15px;
}
</style>
