<template>
  <svg viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
    <path fill="var(--icon-color-primary)" d="m24,3C12.42,3,3,12.42,3,24s9.42,21,21,21,21-9.42,21-21S35.58,3,24,3Zm8.59,15.14l-9,13c-.19.28-.5.46-.84.48-.03,0-.06,0-.08,0-.31,0-.61-.13-.82-.35l-5.17-5.5c-.43-.45-.4-1.17.05-1.59.45-.42,1.16-.4,1.59.05l4.21,4.49,8.21-11.86c.35-.51,1.05-.64,1.57-.28.51.35.64,1.05.29,1.57Z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconRadioButtonOn'
}
</script>
