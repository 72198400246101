<template>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path fill="var(--icon-color-primary)" d="m24,45c-11.58,0-21-9.42-21-21S12.42,3,24,3s21,9.42,21,21-9.42,21-21,21Zm0-40C13.52,5,5,13.52,5,24s8.52,19,19,19,19-8.52,19-19S34.48,5,24,5Z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconRadioButtonOff'
}
</script>
