<template>
  <form id="search_form" class="mb-0">
    <div class="search position-relative flex-grow-1">
      <label for="search" class="sr-only">{{ translate('fe_app_find_wine') }}</label>
      <input id="search" spellcheck="false" autocorrect="off" name="q" @submit.prevent="submit"
             :placeholder="translate('fe_app_find_wine')" v-model="searchTerm" autocomplete="off">
      <button type="submit" :title="translate('search_filters')" @click.prevent="submit">
        <SvgWrapper use="icon_svg_search" class="f-gray" dimensions="18"/>
        <span class="sr-only">{{ translate('search_filters') }}</span>
      </button>
    </div>
  </form>
</template>

<script>
const SvgWrapper = () => import('@/components/basic/atoms/SvgWrapper.vue')

export default {
  name: 'SearchForm',
  components: { SvgWrapper },
  data () {
    return {
      searchTerm: ''
    }
  },
  computed: {
    search () {
      if (this.searchTerm) {
        return { q: this.searchTerm }
      }
      return { supplier: 'VICAMPO' }
    }
  },
  methods: {
    async submit () {
      try {
        await this.$router.push({ name: 'search', query: this.search })
      } catch (_) {
        // swallow NavigationDuplicated
      }
    },
    updateSearchTerm () {
      this.searchTerm = this.$route.query.q ?? ''
    }
  },
  created() {
    this.updateSearchTerm()
  },
  watch: {
    $route() {
      this.updateSearchTerm()
    }
  }
}
</script>

<style lang="scss" scoped>
#search {
  min-height: 50px;
  font-family: $libre;
  font-style: italic;
  font-weight: 400;
  color: $gray;
  height: 40px;
  width: 100%;
  vertical-align: top;
  margin: 0;
  outline: none;
  border-bottom: solid $beige;
  border-width: 0 0 2px 0;
  border-radius: 0;
  background: $white;
  padding: 12px 30px 12px 15px;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: normal;
}

.search > button {
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 100%;
  margin: 0;
  border: 0;
  padding: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
}
</style>
