<template>
  <div class="product-image position-relative d-flex flex-column align-content-center justify-content-center" :class="{'mt-2': isBundle}">
    <img v-if="imageSrc || imageRetinaSrc" :class="{'lozad': imageSrc || imageRetinaSrc}"
         :data-srcset="srcSet"
         :alt="alt">
    <BottleLoadingImageSmall :show-bundle="isBundle"/>
  </div>
</template>

<script>
import UtilityHelper from '@/services/helpers/UtilityHelper'

const BottleLoadingImageSmall = () => import('@/components/basic/atoms/BottleLoadingImageSmall')

export default {
  name: 'ProductImage',
  components: { BottleLoadingImageSmall },
  props: {
    imageSrc: String,
    imageRetinaSrc: String,
    useHeroImage: {
      default: false,
      type: Boolean
    },
    alt: String,
    isBundle: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    srcSet () {
      if (localStorage.getItem('useTemplateImage') === 'bundle') {
        const image = require('@/assets/images/bundle_template.png')
        return UtilityHelper.generateSrcSet(image, image)
      }
      return UtilityHelper.generateSrcSet(this.imageSrc, this.imageRetinaSrc)
    }
  },
  mounted () {
    this.hitLozad()
  },
  watch: {
    imageSrc () {
      this.hitLozad()
    },
    imageRetinaSrc () {
      this.hitLozad()
    }
  }
}
</script>

<style lang="scss" scoped>
.product-image {
  min-height: 150px;
  min-width: 120px;
}

img {
  object-fit: contain;
  object-position: bottom;
  transition: opacity $transition-timing-ease $transition-duration-l;
  transition-delay: .3s;
  opacity: 0;
  max-width: 100%;
  height: 100%;

  &[data-loaded=true] {
    opacity: 1 !important;

    + .loading-image {
      opacity: 0 !important;
    }
  }
}
</style>
