<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" style="display:none">
    <symbol id="icon_svg_heart_empty" viewBox="0 0 48 48">
      <path fill="#E2DCD1"
            d="M45 17c0 5.62-5.16 10.34-5.37 10.55L25 41.58a1.47 1.47 0 0 1-2.06 0L8.34 27.47C8.16 27.31 3 22.59 3 17 3 10.1 7.2 6 14.2 6c4.1 0 8 3.23 9.8 5.06C25.85 9.23 29.7 6 33.8 6c7 0 11.2 4.1 11.2 11z"/>
    </symbol>
    <symbol id="icon_svg_heart_1_4" viewBox="0 0 48 48">
      <path fill="#E2DCD1"
            d="M45 17c0 5.62-5.16 10.33-5.37 10.55L25 41.58a1.47 1.47 0 0 1-2.06 0L8.34 27.47C8.16 27.3 3 22.59 3 17 3 10.1 7.2 6 14.2 6c4.1 0 8 3.23 9.8 5.06C25.85 9.23 29.7 6 33.8 6c7 0 11.2 4.1 11.2 11z"/>
      <path fill="#99003F" d="M13.5 6v26.44l-5.16-5C8.16 27.3 3 22.59 3 17 3 10.34 6.92 6.3 13.5 6z"/>
    </symbol>
    <symbol id="icon_svg_heart_1_2" viewBox="0 0 48 48">
      <path fill="#E2DCD1"
            d="M45 17c0 5.62-5.16 10.33-5.37 10.55L25 41.58a1.47 1.47 0 0 1-2.06 0L8.34 27.47C8.16 27.3 3 22.59 3 17 3 10.1 7.2 6 14.2 6c4.1 0 8 3.23 9.8 5.06C25.85 9.23 29.7 6 33.8 6c7 0 11.2 4.1 11.2 11z"/>
      <path fill="#99003F" d="M24 11.06V42a1.46 1.46 0 0 1-1-.42L8.34 27.47C8.16 27.3 3 22.59 3 17 3 10.1 7.2 6 14.2 6c4.1 0 7.95 3.23 9.8 5.06z"/>
    </symbol>
    <symbol id="icon_svg_heart_3_4" viewBox="0 0 48 48">
      <path fill="#E2DCD1" d="M45 17c0 5.62-5.16 10.33-5.37 10.55L25 41.58a1.47 1.47 0 0 1-2.06 0L8.34 27.47C8.16 27.3 3 22.59 3 17 3 10.1 7.2 6 14.2 6c4.1 0 8 3.23 9.8 5.06C25.85 9.23 29.7 6 33.8 6c7 0 11.2 4.1 11.2 11z"/>
      <path fill="#99003F" d="M34.5 6v26.46L25 41.58a1.47 1.47 0 0 1-2.06 0L8.34 27.47C8.16 27.3 3 22.59 3 17 3 10.1 7.2 6 14.2 6c4.1 0 8 3.23 9.8 5.06C25.85 9.23 29.7 6 33.8 6h.7z"/>
    </symbol>
    <symbol id="icon_svg_heart_full" viewBox="0 0 48 48">
      <path fill="#99003F" d="M45 17c0 5.62-5.16 10.34-5.37 10.55L25 41.58a1.47 1.47 0 0 1-2.06 0L8.34 27.47C8.16 27.31 3 22.59 3 17 3 10.1 7.2 6 14.2 6c4.1 0 8 3.23 9.8 5.06C25.85 9.23 29.7 6 33.8 6c7 0 11.2 4.1 11.2 11z"/>
    </symbol>
  </svg>

</template>

<script>
export default {
  name: 'IconSetHeart'
}
</script>
